import { DEFAULT_TOKEN_LIST } from "@/constants/tokens/default-token-list";
import { execute } from "@/subgraph/graphql/execute";
import { GetRoutesQuery } from "@/subgraph/queries/route-processor/get-routes";
import { GetTokensQuery } from "@/subgraph/queries/v2-ethereum/token/get-tokens";
import { GetMintsQuery } from "@/subgraph/queries/v2-ethereum/user/get-add-liquidity";
import { GetBurnsQuery } from "@/subgraph/queries/v2-ethereum/user/get-remove-liquidity";
import { useQuery } from "@tanstack/react-query";
import { Address, isAddress } from "viem";

export enum TransactionType {
	Mint = "Mint", //add liq
	Burn = "Burn", //remove liq
	Swap = "Swap", //swap
	All = "All", //all
	Liquidity = "Liquidity", //add liq, remove liq
	Staked = "Staked", //stake
	Unstaked = "Unstaked", //unstake
	Harvested = "Harvested", //harvest
}

interface UseTransactionsOpts {
	type: TransactionType | "All";
	refetchInterval?: number;
	first: number;
	skip?: number;
}

const fetchMints = async (walletAddress: Address, first: number, skip?: number) => {
	const res = await execute("v2", GetMintsQuery, {
		walletAddress,
		first,
		skip,
	});

	return res?.data?.mints?.map((mint) => ({
		mints: [mint],
		burns: [],
		swaps: [],
	}));
};

const fetchBurns = async (walletAddress: Address, first: number, skip?: number) => {
	const res = await execute("v2", GetBurnsQuery, {
		walletAddress,
		first,
		skip,
	});

	return res?.data?.burns.map((burn) => ({
		mints: [],
		burns: [burn],
		swaps: [],
	}));
};

const _uniqueTokenAddresses = (tokenAddresses?: string[]) => {
	if (!tokenAddresses) return [];
	const seen = new Set();
	return tokenAddresses.filter((token) => {
		const duplicate = seen.has(token);
		seen.add(token);
		return !duplicate;
	});
};

const _uniqueTokens = (
	tokens?: {
		__typename?: "Token";
		id: any;
		name: string;
		symbol: string;
		decimals: any;
	}[]
) => {
	if (!tokens) return [];
	const seen = new Set();
	return tokens.filter((token) => {
		const duplicate = seen.has(token.id);
		seen.add(token.id);
		return !duplicate;
	});
};

const fetchSwaps = async (walletAddress: Address, first: number, skip?: number) => {
	const _res = await execute("route-processor", GetRoutesQuery, {
		walletAddress: walletAddress?.toLowerCase(),
		first,
		skip,
	});
	const alltokenAddresses = _res?.data?.routes
		?.map((route) => [route?.tokenIn as string, route?.tokenOut as string])
		?.flat();

	const tokenAddresses = _uniqueTokenAddresses(alltokenAddresses);
	const tokenRes = await execute("v2", GetTokensQuery, { tokenAddresses });

	const tokens = tokenRes?.data?.tokenDayDatas?.map((token) => token?.token);
	const uniqueTokens = _uniqueTokens(tokens);

	const swaps = _res?.data?.routes?.map((route) => {
		let tokenIn = uniqueTokens.find((token) => token?.id?.toLowerCase() === route?.tokenIn?.toLowerCase());
		let tokenOut = uniqueTokens.find((token) => token?.id?.toLowerCase() === route?.tokenOut?.toLowerCase());
		if (route?.tokenIn?.toLowerCase() === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
			tokenIn = {
				id: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
				name: "ApeCoin",
				symbol: "APE",
				decimals: 18,
			};
		}
		if (route?.tokenOut?.toLowerCase() === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
			tokenOut = {
				id: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
				name: "ApeCoin",
				symbol: "APE",
				decimals: 18,
			};
		}
		if (!tokenIn) {
			//try to find token from default tokens
			const foundTokenIn = DEFAULT_TOKEN_LIST.find(
				(token) => token.address.toLowerCase() === route?.tokenIn?.toLowerCase()
			);
			if (foundTokenIn) {
				tokenIn = {
					id: foundTokenIn.address,
					name: foundTokenIn.name,
					symbol: foundTokenIn.symbol,
					decimals: foundTokenIn.decimals,
				};
			}
		}
		if (!tokenOut) {
			//try to find token from default tokens
			const foundTokenOut = DEFAULT_TOKEN_LIST.find(
				(token) => token.address.toLowerCase() === route?.tokenOut?.toLowerCase()
			);
			if (foundTokenOut) {
				tokenOut = {
					id: foundTokenOut.address,
					name: foundTokenOut.name,
					symbol: foundTokenOut.symbol,
					decimals: foundTokenOut.decimals,
				};
			}
		}

		return {
			id: route?.id,
			from: route?.from,
			to: route?.to,
			amountIn: route?.amountIn,
			amountOut: route?.amountOut,
			amountOutMin: route?.amountOutMin,
			timestamp: route?.timestamp,
			tokenIn,
			tokenOut,
		};
	});

	return swaps?.map((swap) => ({
		mints: [],
		burns: [],
		swaps: [swap],
	}));
};

const fetchLiquidity = async (walletAddress: Address, first: number, skip?: number) => {
	const [mintsRes, burnsRes] = await Promise.all([
		execute("v2", GetMintsQuery, { walletAddress, first, skip }),
		execute("v2", GetBurnsQuery, { walletAddress, first, skip }),
	]);

	const mints =
		mintsRes?.data?.mints?.map((mint) => ({
			mints: [mint],
			burns: [],
			swaps: [],
		})) || [];

	const burns =
		burnsRes?.data?.burns?.map((burn) => ({
			mints: [],
			burns: [burn],
			swaps: [],
		})) || [];

	return [...mints, ...burns];
};

const fetchAll = async (walletAddress: Address, first: number, skip?: number) => {
	const [mintsRes, burnsRes, swapsRes] = await Promise.all([
		execute("v2", GetMintsQuery, { walletAddress, first, skip }),
		execute("v2", GetBurnsQuery, { walletAddress, first, skip }),
		fetchSwaps(walletAddress, first, skip),
	]);

	const mints =
		mintsRes?.data?.mints?.map((mint) => ({
			mints: [mint],
			burns: [],
			swaps: [],
		})) || [];

	const burns =
		burnsRes?.data?.burns?.map((burn) => ({
			mints: [],
			burns: [burn],
			swaps: [],
		})) || [];

	const swaps = swapsRes ?? [];

	return [...mints, ...burns, ...swaps];
};

export const useTransactions = ({
	walletAddress,
	opts,
}: {
	walletAddress?: Address;
	opts: UseTransactionsOpts;
}) => {
	return useQuery({
		queryKey: ["useTransactions", walletAddress, opts],
		queryFn: async () => {
			if (!walletAddress || !isAddress(walletAddress)) return [];

			let transactions;

			switch (opts.type) {
				case TransactionType.Burn:
					transactions = await fetchBurns(walletAddress, opts?.first, opts?.skip);
					break;
				case TransactionType.Mint:
					transactions = await fetchMints(walletAddress, opts?.first, opts?.skip);
					break;
				case TransactionType.Swap:
					transactions = await fetchSwaps(walletAddress, opts?.first, opts?.skip);
					break;
				case TransactionType.Liquidity:
					transactions = await fetchLiquidity(walletAddress, opts?.first, opts?.skip);
					break;
				case TransactionType.Staked:
					// transactions = await fetchStaked(walletAddress, opts?.first);
					//TODO: implement fetchStaked and remove placeholder
					transactions = [
						{
							mints: [],
							burns: [],
							swaps: [],
						},
					];
					break;
				case TransactionType.Unstaked:
					// transactions = await fetchUnstaked(walletAddress, opts?.first);
					//TODO: implement fetchStaked and remove placeholder
					transactions = [
						{
							mints: [],
							burns: [],
							swaps: [],
						},
					];
					break;
				case TransactionType.All:
					transactions = await fetchAll(walletAddress, opts?.first, opts?.skip);
			}

			if (!transactions || !transactions.length) return [];

			return transactions
				?.flatMap((transaction) => {
					const mints = (transaction.mints as NonNullable<(typeof transaction.mints)[0]>[]).map((mint) => ({
						...mint,
						type: TransactionType.Mint as const,
						_timestamp: mint?.transaction?.timestamp,
					}));

					const burns = (transaction.burns as NonNullable<(typeof transaction.burns)[0]>[]).map((burn) => ({
						...burn,
						type: TransactionType.Burn as const,
						_timestamp: burn?.transaction?.timestamp,
					}));

					const swaps = (transaction.swaps as NonNullable<(typeof transaction.swaps)[0]>[]).map((swap) => ({
						...swap,
						type: TransactionType.Swap as const,
						_timestamp: swap?.timestamp,
					}));

					return [...mints, ...burns, ...swaps];
				})
				?.sort((a, b) => Number(b._timestamp) - Number(a._timestamp));
		},
		enabled: !!walletAddress && isAddress(walletAddress),
		refetchInterval: opts?.refetchInterval,
	});
};

type FetchSwapsReturnType = NonNullable<Awaited<ReturnType<typeof fetchSwaps>>>;
export type SwapTransactionType = FetchSwapsReturnType[0]["swaps"][0] & {
	_timestamp: string;
	type: TransactionType.Swap;
};

type FetchMintsReturnType = NonNullable<Awaited<ReturnType<typeof fetchMints>>>;
export type MintTransactionType = FetchMintsReturnType[0]["mints"][0] & {
	_timestamp: string;
	type: TransactionType.Mint;
};

type FetchBurnsReturnType = NonNullable<Awaited<ReturnType<typeof fetchBurns>>>;
export type BurnTransactionType = FetchBurnsReturnType[0]["burns"][0] & {
	_timestamp: string;
	type: TransactionType.Burn;
};

export type TransactionReturnType = MintTransactionType | BurnTransactionType | SwapTransactionType;
