import { useTokens } from "@/hooks/useTokens";
import { TokenIcon } from "../shared/token-icon";
import { CHAIN_ID } from "@/constants/chain-id";
import { useBalancesWeb3 } from "@/hooks/useBalancesWeb3";
import { useAccount } from "wagmi";
import { useSortedTokenList } from "@/hooks/useSortedTokenList";
import { Amount, Currency, Token, Type } from "sushi/currency";
import { getAddress, zeroAddress } from "viem";
import { formatDisplayAmount, shortenString } from "@/utils/formatters";
import { useTokenInfo } from "@/hooks/useTokenInfo";
import { useMemo } from "react";
import { multiplyDecimals } from "@/utils/math/decimal-math";
import { formatUSD } from "sushi/format";

export const WalletSection = () => {
	const { address } = useAccount();
	const { data: defaultTokenMap } = useTokens({
		chainId: CHAIN_ID,
	});

	const { data: tokenBalances } = useBalancesWeb3({
		chainId: CHAIN_ID,
		currencies: Object.values(defaultTokenMap ?? {}) ?? [],
		account: address,
		enabled: !!defaultTokenMap,
	});

	const { data: sortedTokenList, isLoading } = useSortedTokenList({
		query: "",
		customTokenMap: {},
		tokenMap: defaultTokenMap,
		pricesMap: undefined,
		balancesMap: tokenBalances ?? {},
		chainId: CHAIN_ID,
		includeNative: true,
	});

	if (isLoading) {
		return (
			<div className="flex flex-col gap-4">
				{new Array(3).fill(null).map((_, idx) => (
					<LoadingSkeleton key={idx} />
				))}
			</div>
		);
	}

	return (
		<div className="flex flex-col pr-2">
			{sortedTokenList?.map((token, idx) => (
				<WalletItem
					token={token}
					key={idx}
					balance={tokenBalances?.[token.isNative ? zeroAddress : getAddress(token?.wrapped.address)]}
				/>
			))}
		</div>
	);
};

const WalletItem = ({ token, balance }: { token: Type; balance?: Amount<Currency> }) => {
	const { data: tokenData } = useTokenInfo({ tokenAddress: token?.wrapped?.address });

	const usdAmount = useMemo(() => {
		if (tokenData?.priceUSD && balance) {
			const _usdPrice = tokenData?.priceUSD?.toString() ?? "0";
			return multiplyDecimals(_usdPrice, balance.toExact());
		} else {
			return "0.00";
		}
	}, [tokenData, balance]);

	return (
		<div className="flex justify-between w-full py-2 items-center">
			<div className="flex gap-2 items-center">
				<TokenIcon width={42} height={42} currency={token} />
				<div className="flex flex-col">
					<div className="font-bold">{shortenString(token?.symbol ?? "")}</div>
					<div className="text-sm">{""}</div>
				</div>
			</div>
			<div className="flex flex-col items-end">
				<div>{formatUSD(usdAmount)}</div>
				<div className="text-sm">
					{formatDisplayAmount(balance?.toSignificant(4) ?? "0")} {shortenString(token?.symbol ?? "")}
				</div>
			</div>
		</div>
	);
};

const LoadingSkeleton = () => (
	<div className="flex justify-between animate-pulse w-full">
		<div className="flex gap-2">
			<div className="w-8 h-8 bg-dialog-content rounded-full"></div>
			<div className="flex flex-col gap-1">
				<div className="w-24 h-4 bg-dialog-content rounded"></div>
				<div className="w-16 h-4 bg-dialog-content rounded"></div>
			</div>
		</div>
		<div className="flex flex-col items-end gap-1">
			<div className="w-24 h-4 bg-dialog-content rounded"></div>
			<div className="w-16 h-4 bg-dialog-content rounded"></div>
		</div>
	</div>
);
