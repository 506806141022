import { TransactionReturnType, TransactionType, useTransactions } from "@/hooks/user/useTransactions";
import { Button } from "../components/button";
import { External } from "../components/icons/external";
import { LinkExternal } from "../components/link";
import { TokenIcon } from "../shared/token-icon";
import { useAccount } from "wagmi";
import { formatTime, shortenString } from "@/utils/formatters";
import { useMemo } from "react";
import { getBlockExplorerForTxn } from "@/utils/block-explorer";
import { CHAIN_ID } from "@/constants/chain-id";
import { formatNumber } from "sushi/format";
import { Native, Token } from "sushi/currency";
import { IconList } from "../components/icon-list";
import { formatUnits } from "viem";

export const ActivitySection = () => {
	const { address } = useAccount();
	const { data, isLoading } = useTransactions({
		walletAddress: address,
		opts: {
			type: TransactionType.All,
			first: 5,
			refetchInterval: 20000,
		},
	});

	if (isLoading) {
		return (
			<div className="flex flex-col gap-4">
				{new Array(3).fill(null).map((_, idx) => (
					<LoadingSkeleton key={idx} />
				))}
			</div>
		);
	}

	if ((!data || data?.length === 0) && !isLoading) {
		return (
			<p className="text-sm text-center text-muted-foreground mt-4">
				Swap or add liquidity to see a <br /> transaction record here.
			</p>
		);
	}

	return (
		<div className="flex flex-col pr-2">
			{data?.map((txn, idx) => (
				<ActivityItem key={idx} txn={txn} />
			))}
		</div>
	);
};

const ActivityItem = ({ txn }: { txn: TransactionReturnType }) => {
	const title = useMemo(() => {
		const type = txn.type;
		switch (type) {
			case TransactionType.Burn:
				return "Removed Liquidity";
			case TransactionType.Mint:
				return "Added Liquidity";
			case TransactionType.Swap:
				return "Swapped";
			default:
				return "N/A";
		}
	}, [txn]);

	const description = useMemo(() => {
		const type = txn.type;
		switch (type) {
			case TransactionType.Burn:
				return `${formatNumber(txn?.liquidity)} SLP for ${formatNumber(txn.amount0)} ${shortenString(
					txn?.pair?.token0?.symbol
				)} + ${formatNumber(txn.amount1)} ${shortenString(txn?.pair?.token1?.symbol)}`;
			case TransactionType.Mint:
				return `${formatNumber(txn.amount0)} ${shortenString(txn?.pair?.token0?.symbol)} + ${formatNumber(
					txn.amount1
				)} ${shortenString(txn?.pair?.token1?.symbol)} for ${formatNumber(txn?.liquidity)} SLP`;
			case TransactionType.Swap:
				const token0 = txn?.tokenIn;
				const token1 = txn?.tokenOut;
				const amountIn = formatUnits(BigInt(txn?.amountIn), token0?.decimals ?? 18);
				const amountOut = formatUnits(BigInt(txn?.amountOut), token1?.decimals ?? 18);

				return `${formatNumber(amountIn)} ${token0?.symbol ?? "N/A"} for ${formatNumber(amountOut)} ${
					token1?.symbol ?? "N/A"
				}`;
			default:
				return "N/A";
		}
	}, [txn]);

	const token0ToShow = useMemo(() => {
		if (txn?.type === TransactionType.Swap) {
			const token0 = txn?.tokenIn;
			if (token0?.id === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
				return Native.onChain(CHAIN_ID);
			}
			return new Token({
				chainId: CHAIN_ID,
				address: token0?.id ?? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee1",
				decimals: token0?.decimals ?? 18,
				name: token0?.name ?? "N/A",
				symbol: token0?.symbol ?? "N/A",
			});
		}

		const _token0 = txn?.pair?.token0;
		return new Token({
			chainId: CHAIN_ID,
			address: _token0?.id ?? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee1",
			decimals: _token0?.decimals ?? 18,
			name: _token0?.name ?? "N/A",
			symbol: _token0?.symbol ?? "N/A",
		});
	}, [txn]);

	const token1ToShow = useMemo(() => {
		if (txn?.type === TransactionType.Swap) {
			const token1 = txn?.tokenOut;
			if (token1?.id === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
				return Native.onChain(CHAIN_ID);
			}
			return new Token({
				chainId: CHAIN_ID,
				address: token1?.id ?? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee1",
				decimals: token1?.decimals ?? 18,
				name: token1?.name ?? "N/A",
				symbol: token1?.symbol ?? "N/A",
			});
		}

		const _token1 = txn?.pair?.token1;
		return new Token({
			chainId: CHAIN_ID,
			address: _token1?.id ?? "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee1",
			decimals: _token1?.decimals ?? 18,
			name: _token1?.name ?? "N/A",
			symbol: _token1?.symbol ?? "N/A",
		});
	}, [txn]);
	const txnId = txn?.type === TransactionType.Swap ? txn?.id?.split("-")?.[0] : txn?.transaction?.id;

	return (
		<div className="flex justify-between w-full py-2 items-center">
			<div className="flex gap-2 items-center">
				<IconList iconWidth={28} iconHeight={28}>
					<TokenIcon width={42} height={28} currency={token0ToShow} />
					<TokenIcon width={28} height={28} currency={token1ToShow} />
				</IconList>
				<div className="flex flex-col">
					<div className="font-bold text-sm">{title}</div>
					<div className="text-xs">{description}</div>
				</div>
			</div>
			<div className="flex items-center text-xs text-right">
				<div>{formatTime(Number(txn?._timestamp) * 1000, "MMM D, YYYY")}</div>
				<LinkExternal href={getBlockExplorerForTxn(CHAIN_ID, txnId)}>
					<Button variant="ghost">
						<External width={12} height={12} />
					</Button>
				</LinkExternal>
			</div>
		</div>
	);
};
const LoadingSkeleton = () => (
	<div className="flex justify-between animate-pulse w-full">
		<div className="flex gap-2">
			<div className="w-8 h-8 bg-dialog-content rounded-full"></div>
			<div className="flex flex-col gap-1">
				<div className="w-24 h-4 bg-dialog-content rounded"></div>
				<div className="w-16 h-4 bg-dialog-content rounded"></div>
			</div>
		</div>
		<div className="flex items-center text-sm gap-1">
			<div className="w-4 h-4 bg-dialog-content rounded"></div>
			<div className="w-4 h-4 bg-dialog-content rounded"></div>
		</div>
	</div>
);
