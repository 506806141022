import { useAccount } from "wagmi";
import { Button } from "../components/button";
import { shortenAddress } from "sushi/format";
import { Chevron } from "../components/icons/chevron";
import { Avatar } from "./avatar";
import { Popover, PopoverContent, PopoverTrigger } from "../components/popover";
import { Close } from "../components/icons/close";
import { Dialog, DialogBody, DialogClose, DialogContent, DialogTrigger } from "../components/dialog";
import { UserAddress } from "./user-address";
import { Disconnect } from "./disconnect";
import { Explorer } from "./explorer";
import { BuyCrypto } from "./buy-crypto";
import { WalletActivityTabs } from "./wallet-activity-tabs";

export const UserProfile = () => {
	const { address } = useAccount();

	return (
		<>
			<Popover>
				<PopoverTrigger asChild className="hidden md:flex">
					<Button variant={"always-dark"}>
						<div className="flex items-center gap-2">
							<Avatar size="sm" />
							<div>{shortenAddress(address as string)}</div>
							<div>
								<Chevron width={14} height={8} />
							</div>
						</div>
					</Button>
				</PopoverTrigger>

				<PopoverContent align={"end"} className="flex-col hidden md:flex w-[348px]">
					<section className="bg-dialog-body p-4 flex flex-col gap-2 pt-8 relative">
						<Disconnect className="absolute right-2 top-2" />
						<_UserProfile />
					</section>
				</PopoverContent>
			</Popover>
			<Dialog>
				<DialogTrigger asChild className="flex md:hidden">
					<Button variant={"always-dark"} size={"sm"}>
						<div className="flex items-center gap-2">
							<Avatar size="sm" />
							<div>{shortenAddress(address as string)}</div>
						</div>
					</Button>
				</DialogTrigger>

				<DialogContent
					overlayClassName="md:hidden"
					aria-describedby={undefined}
					className="block md:hidden pt-2 h-[85vh]">
					<DialogBody className="flex flex-col gap-2 h-full p-3 relative">
						<DialogClose asChild className="absolute top-3 left-3">
							<Button variant="ghost" size={"sm"}>
								<Close width={16} height={16} />
							</Button>
						</DialogClose>
						<Disconnect className="absolute right-2.5 top-2.5" />
						<div className="mt-4 w-full">
							<_UserProfile />
						</div>
					</DialogBody>
				</DialogContent>
			</Dialog>
		</>
	);
};

const _UserProfile = () => {
	return (
		<div className="flex flex-col gap-3 items-center">
			<Avatar size="lg" />
			<UserAddress />
			<section className="flex gap-8">
				<Explorer />
				{/* <BuyCrypto /> */}
			</section>

			<WalletActivityTabs />
		</div>
	);
};
