"use client";
import { cn } from "@/lib/utils";
import { SaruIcon } from "./icons/saru-icon";
import { usePathname } from "next/navigation";
import { ConnectButton } from "../connect-wallet/connect-button";
import { useIsMounted } from "@/hooks/useIsMounted";
import { useAccount } from "wagmi";
import { UserProfile } from "../user-profile/user-profile";
import { Settings } from "../settings/settings";
import { MobileNavigation } from "./mobile-navigation";
import { LinkInternal } from "@/ui/components/link";
import { useMemo } from "react";

export const LINKS = [
	{ label: "Swap", href: "/swap" },
	{ label: "Pools", href: "/pools" },
	// { label: "Stake", href: "/stake" },
	// { label: "Bridge", href: "/bridge" },
	{ label: "Portfolio", href: "/portfolio" },
];

const PATHS_TO_NOT_SHOW_NAV = ["/terms-of-use", "/privacy-policy", "/cookie-policy"];

export const Navigation = () => {
	const pathname = usePathname();
	const { address } = useAccount();
	const isMounted = useIsMounted();

	const isConnectedAndMounted = isMounted && address;

	const showNavItems = useMemo(() => {
		return !PATHS_TO_NOT_SHOW_NAV.includes(pathname);
	}, [pathname]);

	return (
		<>
			<nav
				className={cn(
					"border-b h-[64px] px-4 py-3 md:px-14 lg:px-20 md:py-4 md:h-[76px] flex items-center justify-between flex-grow gap-4 relative bg-header"
				)}>
				{showNavItems ? (
					<ul className="items-center gap-8 hidden md:flex">
						{LINKS.map(({ label, href }) => (
							<li key={label}>
								<LinkInternal
									className={cn(pathname === href ? "text-primary font-bold" : "text-secondary")}
									href={href}>
									{label}
								</LinkInternal>
							</li>
						))}
					</ul>
				) : null}
				<LinkInternal
					href="/"
					className="hidden md:block absolute left-1/2 -translate-x-1/2 top-[58%] bg-background">
					<SaruIcon width={60} height={60} />
				</LinkInternal>
				{showNavItems ? (
					<div className={cn("hidden md:flex items-center gap-6", isConnectedAndMounted ? "gap-2" : "")}>
						<Settings />
						{isConnectedAndMounted ? <UserProfile /> : <ConnectButton size="lg" />}
					</div>
				) : null}

				<div className="flex md:hidden items-center gap-4">
					<LinkInternal href="/">
						<SaruIcon width={31} height={23} />
					</LinkInternal>
				</div>
				{showNavItems ? (
					<div
						className={cn(
							"flex md:hidden items-center gap-2 md:gap-4 pr-2",
							isConnectedAndMounted ? "pr-0" : "gap-4"
						)}>
						<Settings />
						{isConnectedAndMounted ? <UserProfile /> : <ConnectButton size="sm" />}
					</div>
				) : null}
			</nav>
			{showNavItems ? (
				<div className="block md:hidden">
					<MobileNavigation />
				</div>
			) : null}
		</>
	);
};
