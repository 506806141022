/* eslint-disable */
import * as types from './graphql';



/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n\tquery Routes($walletAddress: Bytes!, $first: Int = 1000, $skip: Int = 0) {\n\t\troutes(\n\t\t\tfirst: $first\n\t\t\tskip: $skip\n\t\t\torderBy: timestamp\n\t\t\torderDirection: desc\n\t\t\twhere: { from: $walletAddress }\n\t\t) {\n\t\t\tid\n\t\t\tfrom\n\t\t\tto\n\t\t\ttokenIn\n\t\t\ttokenOut\n\t\t\tamountIn\n\t\t\tamountOut\n\t\t\tamountOutMin\n\t\t\ttimestamp\n\t\t}\n\t}\n": types.RoutesDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery Routes($walletAddress: Bytes!, $first: Int = 1000, $skip: Int = 0) {\n\t\troutes(\n\t\t\tfirst: $first\n\t\t\tskip: $skip\n\t\t\torderBy: timestamp\n\t\t\torderDirection: desc\n\t\t\twhere: { from: $walletAddress }\n\t\t) {\n\t\t\tid\n\t\t\tfrom\n\t\t\tto\n\t\t\ttokenIn\n\t\t\ttokenOut\n\t\t\tamountIn\n\t\t\tamountOut\n\t\t\tamountOutMin\n\t\t\ttimestamp\n\t\t}\n\t}\n"): typeof import('./graphql').RoutesDocument;


export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}
