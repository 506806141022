import { Decimal } from "decimal.js";
import { withoutScientificNotation } from "sushi";

const isValidInput = /^\d*\.?\d+$/; //all positive numbers

export const addDecimals = (a: string, b: string) => {
	if (a === undefined || b === undefined) return "";
	const num1 = withoutScientificNotation(a) ?? "";
	const num2 = withoutScientificNotation(b) ?? "";
	if (!isValidInput.test(num1) || !isValidInput.test(num2)) return "";
	const _a = new Decimal(a);
	const _b = new Decimal(b);
	return _a.add(_b).toString();
};

export const divideDecimals = (a: string, b: string) => {
	if (a === undefined || b === undefined) return "";
	const num1 = withoutScientificNotation(a) ?? "";
	const num2 = withoutScientificNotation(b) ?? "";
	if (!isValidInput.test(num1) || !isValidInput.test(num2)) return "";
	const _a = new Decimal(a);
	const _b = new Decimal(b);
	return _a.div(_b).toString();
};

export const multiplyDecimals = (a: string, b: string) => {
	if (a === undefined || b === undefined) return "";
	const num1 = withoutScientificNotation(a) ?? "";
	const num2 = withoutScientificNotation(b) ?? "";
	if (!isValidInput.test(num1) || !isValidInput.test(num2)) return "";
	const _a = new Decimal(a);
	const _b = new Decimal(b);
	return _a.mul(_b).toString();
};
