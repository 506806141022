import { graphql } from "../../../graphql/v2-ethereum/index";

export const GetBurnsQuery = graphql(`
	query Burns($walletAddress: Bytes!, $first: Int = 1000, $skip: Int = 0) {
		burns(
			first: $first
			skip: $skip
			orderBy: timestamp
			orderDirection: desc
			where: { sender: $walletAddress }
		) {
			id
			logIndex
			amountUSD
			amount0
			amount1
			liquidity
			sender
			to
			pair {
				id
				token0 {
					id
					name
					symbol
					decimals
				}
				token1 {
					id
					name
					symbol
					decimals
				}
			}
			transaction {
				timestamp
				blockNumber
				id
			}
		}
	}
`);
