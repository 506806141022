import { http } from "viem";

export const curtis = {
	contracts: {
		// readonly multicall3: {
		// 	readonly address: `0x${string}`;
		// 	readonly blockCreated: 16146628;
		// };
	},
	blockExplorers: {
		default: {
			name: "Curtis Explorer",
			url: "https://curtis.explorer.caldera.xyz",
			apiUrl: "https://curtis.explorer.caldera.xyz/api/v2",
		},
	},
	id: 33111,
	name: "Curtis",
	nativeCurrency: {
		name: "ApeCoin",
		symbol: "APE",
		decimals: 18,
	},
	rpcUrls: {
		default: {
			http: ["https://curtis.rpc.caldera.xyz/http"],
			wss: ["wss://curtis.rpc.caldera.xyz/ws"],
		},
	},
	// readonly sourceId?: number | undefined;
	// readonly testnet?: boolean | undefined;
	// readonly custom?: Record<string, unknown> | undefined;
	// readonly formatters?: undefined;
	// readonly serializers?: import("viem").ChainSerializers<undefined> | undefined;
	// readonly fees?: import("viem").ChainFees<undefined> | undefined;
} as const;

export const apeChain = {
	contracts: {
		multicall3: {
			address: "0xcA11bde05977b3631167028862bE2a173976CA11",
			blockCreated: 20889,
		},
	},
	blockExplorers: {
		default: {
			name: "ApeScan",
			url: "https://apescan.io/",
			apiUrl: "https://api.apescan.io/api",
		},
	},
	id: 33139,
	name: "ApeChain",
	nativeCurrency: {
		name: "ApeCoin",
		symbol: "APE",
		decimals: 18,
	},
	rpcUrls: {
		default: {
			http: ["https://rpc.apechain.com/http"],
			wss: ["wss://rpc.apechain.com/ws"],
		},
	},
	// readonly sourceId?: number | undefined;
	// readonly testnet?: boolean | undefined;
	// readonly custom?: Record<string, unknown> | undefined;
	// readonly formatters?: undefined;
	// readonly serializers?: import("viem").ChainSerializers<undefined> | undefined;
	// readonly fees?: import("viem").ChainFees<undefined> | undefined;
} as const;

export const publicTransports = {
	33111: http(curtis.rpcUrls.default.http[0]),
	33139: http(apeChain.rpcUrls.default.http[0]),
} as const;

export const publicChains = [
	{
		blockExplorers: {
			default: {
				name: "Curtis Explorer",
				url: "https://curtis.explorer.caldera.xyz",
				apiUrl: "https://curtis.explorer.caldera.xyz/api/v2",
			},
		},
		contracts: {
			// readonly ensRegistry: {
			// 	readonly address: "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e";
			// };
			// readonly ensUniversalResolver: {
			// 	readonly address: "0xce01f8eee7E479C928F8919abD53E553a36CeF67";
			// 	readonly blockCreated: 19258213;
			// };
			multicall3: {
				address: "0xc454132B017b55b427f45078E335549A7124f5f7",
				blockCreated: 6661339,
			},
		},
		id: 33111,
		name: "Curtis",
		nativeCurrency: {
			name: "ApeCoin",
			symbol: "APE",
			decimals: 18,
		},
		rpcUrls: {
			default: {
				http: ["https://curtis.rpc.caldera.xyz/http"],
				wss: ["wss://curtis.rpc.caldera.xyz/ws"],
			},
		},
		// sourceId: undefined;
		// testnet: undefined;
		// custom:undefined
		// formatters: undefined;
		// serializers: import("viem").ChainSerializers<undefined> | undefined;
		// fees?: import("viem").ChainFees<undefined> | undefined;
	},
	{
		blockExplorers: {
			default: {
				name: "ApeScan",
				url: "https://apescan.io/",
				apiUrl: "https://api.apescan.io/api",
			},
		},
		contracts: {
			multicall3: {
				address: "0xcA11bde05977b3631167028862bE2a173976CA11",
				blockCreated: 20889,
			},
		},
		id: 33139,
		name: "ApeChain",
		nativeCurrency: {
			name: "ApeCoin",
			symbol: "APE",
			decimals: 18,
		},
		rpcUrls: {
			default: {
				http: ["https://rpc.apechain.com/http"],
				wss: ["wss://rpc.apechain.com/ws"],
			},
		},
		// sourceId: undefined;
		// testnet: undefined;
		// custom:undefined
		// formatters: undefined;
		// serializers: import("viem").ChainSerializers<undefined> | undefined;
		// fees?: import("viem").ChainFees<undefined> | undefined;
	},
] as const;

export const ChainId = {
	CURTIS: 33111,
	APE_CHAIN: 33139,
	ETH_MAINNET: 1,
} as const;
export type ChainId = (typeof ChainId)[keyof typeof ChainId];

export const ChainKey = {
	33111: "curtis",
	1: "eth_mainnet",
	33139: "ape_chain",
} as const;
export type ChainKey = (typeof ChainKey)[keyof typeof ChainKey];
