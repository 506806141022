"use client";

import { Dialog, DialogBody, DialogContent } from "./dialog";
import { useCallback, useState } from "react";
import { LinkExternal, LinkInternal } from "./link";
import { Button } from "./button";
import { Divider } from "./divider";
import { Switch } from "./switch";
import { usePathname } from "next/navigation";
import { announceCookieChange } from "@/utils/cookie";
import { DialogTitle } from "@radix-ui/react-dialog";

const cookieTypes = ["essential", "functional", "performance"] as const;

export type CookieType = (typeof cookieTypes)[number];

type BaseAction = "accept" | "reject" | "manage";

type ManageAction =
	| {
			type: "confirm";
	  }
	| {
			type: "set";
			cookieType: CookieType;
			enabled: boolean;
	  }
	| {
			type: "reject";
	  };

export const CookieConsent = ({ hasCookiesConfirmed }: { hasCookiesConfirmed: boolean }) => {
	const [open, setOpen] = useState(!hasCookiesConfirmed);

	const [view, setView] = useState<"index" | "manage">("index");
	const pathname = usePathname();
	const [enabledCookieSet, setEnabledCookieSet] = useState<Set<CookieType>>(new Set(cookieTypes));

	const onConfirm = useCallback((cookieSet: Set<CookieType>) => {
		const cookieString = Array.from(cookieSet).join(",");
		document.cookie = `accepted-cookies=${cookieString}; max-age=31536000; path=/`;
		announceCookieChange();

		setOpen(false);
	}, []);

	const onCookieAction = useCallback(
		(action: BaseAction) => {
			switch (action) {
				case "accept":
					onConfirm(new Set<CookieType>(cookieTypes));
					break;
				case "reject":
					onConfirm(new Set<CookieType>(["essential"]));
					break;
				case "manage":
					handleManageCookies();
					break;
			}
		},
		[onConfirm]
	);

	const onManageAction = useCallback(
		(action: ManageAction) => {
			switch (action.type) {
				case "confirm":
					onConfirm(enabledCookieSet);
					break;
				case "reject":
					onConfirm(new Set<CookieType>(["essential"]));
					break;
				case "set":
					setEnabledCookieSet((prev) => {
						const next = new Set(prev);
						if (action.enabled) {
							next.add(action.cookieType);
						} else {
							next.delete(action.cookieType);
						}
						return next;
					});
					break;
			}
		},
		[enabledCookieSet, onConfirm]
	);

	const handleAcceptAll = () => {
		// Accept all cookies
		onCookieAction("accept");
	};
	const handleManageCookies = () => {
		// Manage cookies
		setView("manage");
	};

	const handleRejectAllNonEssential = () => {
		// Reject all non-essential cookies
		onManageAction({ type: "reject" });
	};

	const confirmChoices = () => {
		// Confirm choices
		onManageAction({ type: "confirm" });
	};

	if (hasCookiesConfirmed || pathname === "/privacy-policy") {
		return null;
	}

	return (
		<Dialog open={open}>
			<DialogContent
				variant={"desktop"}
				aria-describedby={undefined}
				className="double-rabbet-inner-clip dark:bg-popover bg-[#9DB7E7] !max-w-2xl md:!top-[90%] md:!translate-y-[-90%] !top-[80%] !translate-y-[-80%]">
				<DialogTitle className="hidden" />
				<DialogBody className="p-0">
					{view === "index" ? (
						<IndexView
							handleAcceptAll={handleAcceptAll}
							handleManageCookies={handleManageCookies}
							handleRejectAllNonEssential={handleRejectAllNonEssential}
						/>
					) : null}
					{view === "manage" ? (
						<ManageView
							confirmChoices={confirmChoices}
							handleRejectAllNonEssential={handleRejectAllNonEssential}
							onManageAction={onManageAction}
							cookieSet={enabledCookieSet}
						/>
					) : null}
				</DialogBody>
			</DialogContent>
		</Dialog>
	);
};

const ManageView = ({
	handleRejectAllNonEssential,
	confirmChoices,
	cookieSet,
	onManageAction,
}: {
	handleRejectAllNonEssential: () => void;
	confirmChoices: () => void;
	cookieSet: Set<CookieType>;
	onManageAction: (action: ManageAction) => void;
}) => {
	return (
		<div className="flex flex-col w-full">
			<div className="bg-white dark:bg-dialog-content py-3 px-6 w-full">
				<h1 className="text-lg font-bold">MANAGE COOKIE PREFERENCES</h1>
			</div>
			<div className="flex flex-col gap-4 px-6 py-3">
				<div className="flex justify-between items-center gap-2">
					<p>Strictly Necessary Cookies</p>
					<Switch checked={true} disabled />
				</div>
				<Divider className="bg-[#2E323866]" />
				<div className="flex justify-between items-center gap-2">
					<p>Performance Cookies</p>
					<Switch
						checked={cookieSet.has("performance")}
						onCheckedChange={(enabled) => onManageAction({ type: "set", cookieType: "performance", enabled })}
					/>
				</div>
				<Divider className="bg-[#2E323866]" />
				<div className="flex justify-between items-center gap-2">
					<p>Functional Cookies</p>
					<Switch
						checked={cookieSet.has("functional")}
						onCheckedChange={(enabled) => onManageAction({ type: "set", cookieType: "functional", enabled })}
					/>
				</div>
				<div className="flex flex-col gap-3 md:gap-8 md:flex-row items-center w-full justify-center mt-4">
					<Button onClick={confirmChoices} className="uppercase w-full md:w-fit">
						Confirm My Choices
					</Button>
					<Button
						variant={"tertiary"}
						onClick={handleRejectAllNonEssential}
						className="uppercase w-full md:w-fit">
						Reject all non-essential
					</Button>
				</div>
			</div>
		</div>
	);
};

const IndexView = ({
	handleAcceptAll,
	handleManageCookies,
	handleRejectAllNonEssential,
}: {
	handleAcceptAll: () => void;
	handleManageCookies: () => void;
	handleRejectAllNonEssential: () => void;
}) => {
	return (
		<div className="flex flex-col p-6 gap-2 relative">
			<h1 className="text-lg font-bold">
				Sushi Labs and our third-party service providers may use cookies as set forth in our Cookie Policy
			</h1>
			<p className="text-sm">
				Which process your personal data. You may manage your cookie preferences below. Even if you reject all
				cookies, you hereby consent to the collection of your personal data by us and our service providers as
				you use our services, through technologies other than cookies, as described in our{" "}
				<LinkExternal href="/privacy-policy" className="text-accent underline">
					Privacy Policy
				</LinkExternal>
				, including when such collection may be considered an interception of communications by third parties.
			</p>
			<Divider className="my-2" />
			<div className="flex flex-col gap-3 md:gap-8 md:flex-row items-center w-full justify-center">
				<Button onClick={handleAcceptAll} className="uppercase w-full md:w-fit">
					Accept All
				</Button>
				<Button variant={"tertiary"} onClick={handleManageCookies} className="uppercase w-full md:w-fit">
					Manage cookies
				</Button>
				<Button
					variant={"tertiary"}
					onClick={handleRejectAllNonEssential}
					className="uppercase w-full md:w-fit">
					Reject all non-essential
				</Button>
			</div>
		</div>
	);
};
