import { graphql } from "../../../graphql/v2-ethereum/index";

export const GetTokensQuery = graphql(`
	query GetTokens($tokenAddresses: [String!]!) {
		tokenDayDatas(where: { token_in: $tokenAddresses }) {
			token {
				id
				name
				symbol
				decimals
			}
		}
	}
`);
