import { graphql } from "../../graphql/route-processor/gql";

export const GetRoutesQuery = graphql(`
	query Routes($walletAddress: Bytes!, $first: Int = 1000, $skip: Int = 0) {
		routes(
			first: $first
			skip: $skip
			orderBy: timestamp
			orderDirection: desc
			where: { from: $walletAddress }
		) {
			id
			from
			to
			tokenIn
			tokenOut
			amountIn
			amountOut
			amountOutMin
			timestamp
		}
	}
`);
