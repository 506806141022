"use client";

import { cn } from "@/lib/utils";
import * as HoverCardPrimitive from "@radix-ui/react-hover-card";
import * as React from "react";
import { InfoHover } from "./icons/info-hover";

const HoverCard = HoverCardPrimitive.Root;

const HoverCardTrigger = HoverCardPrimitive.Trigger;

const HoverCardContent = React.forwardRef<
	React.ElementRef<typeof HoverCardPrimitive.Content>,
	React.ComponentPropsWithoutRef<typeof HoverCardPrimitive.Content>
>(({ className, align = "center", side = "right", sideOffset = 4, ...props }, ref) => (
	<HoverCardPrimitive.Content
		ref={ref}
		align={align}
		sideOffset={sideOffset}
		side={side}
		className={cn(
			"z-50 overflow-hidden p-2 bg-popover text-popover-text shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
			className
		)}
		{...props}>
		{props.children}
		<HoverCardPrimitive.Arrow className="fill-popover" />
	</HoverCardPrimitive.Content>
));
HoverCardContent.displayName = HoverCardPrimitive.Content.displayName;

const FullHoverCard: React.FC<{
	title?: string;
	description: string;
	align: "center" | "end" | "start";
	className?: string;
}> = ({ title, description, align = "center", className }) => {
	const [open, setOpen] = React.useState(false);
	const toggle = () => setOpen(!open);
	return (
		<HoverCard open={open} onOpenChange={toggle} openDelay={0} closeDelay={0}>
			<HoverCardTrigger onClick={toggle}>
				<InfoHover width={16} height={16} />
			</HoverCardTrigger>
			<HoverCardPrimitive.Portal>
				<HoverCardContent align={align} className={cn("p-4 max-w-[320px] z-[1080]", className ?? "")}>
					<div>
						{title ? <h3 className="font-bold text-lg text-primary">{title}</h3> : null}
						<p className="text-tertiary text-sm">{description}</p>
					</div>
				</HoverCardContent>
			</HoverCardPrimitive.Portal>
		</HoverCard>
	);
};

export { HoverCard, HoverCardContent, HoverCardPrimitive, HoverCardTrigger, FullHoverCard };
